/*eslint-disable*/

import dayjs from 'dayjs';
import { AES, enc } from 'crypto-js';
import { customAlphabet, nanoid } from 'nanoid';

import isBetween from 'dayjs/plugin/isBetween';
import { SURVEY_FLOW_TYPES } from './Constant';
dayjs.extend(isBetween);

export const setItem = (type, key, value) => type.setItem(key, value);

export const getItem = (type, key) => type.getItem(key);

export const decodeLoginData = (hash) => {
  const decryptedBytes = AES.decrypt(hash, process.env.REACT_APP_SECRETKEY);
  const decryptedData = decryptedBytes.toString(enc.Utf8);
  return decryptedData;
};

export const removeItem = (type, key) => type.removeItem(key);

export const removeAll = (type) => type.clear();

export const getFirstLetter = (name) => {
  if (!name) return false;

  return name.charAt(0).toUpperCase();
};

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const isAlphaNumericValue = (value) => {
  return value && /^(?=.*[a-zA-Z])(?=.*[0-9]).+$/.test(value);
};

export const isValidEmail = (value) => {
  return value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value);
};

export const findWeekFromDate = (currentDate, weeks) => {
  currentDate = dayjs(currentDate);

  const currentWeekObj = weeks.find((week) => {
    return dayjs(currentDate).isBetween(
      dayjs(week.startDate),
      dayjs(week.endDate),
      null,
      '[]',
    );
  });

  return currentWeekObj;
};

export const getWeeksBetweenTwoDates = (startDate, endDate) => {
  startDate = dayjs(startDate);
  endDate = dayjs(endDate);
  const weeks = [];

  let currentWeek = dayjs(startDate).startOf('week');
  while (currentWeek <= endDate) {
    const endDateOfWeek = dayjs(currentWeek).endOf('week');

    weeks.push({
      id: crypto.randomUUID(),
      name: `${currentWeek.format('YYYY-MM-DD')} to ${endDateOfWeek.format(
        'YYYY-MM-DD',
      )}`,
      startDate: currentWeek.format('YYYY-MM-DD'),
      endDate: endDateOfWeek.format('YYYY-MM-DD'),
    });
    currentWeek = dayjs(currentWeek).add(1, 'week').startOf('week');
  }

  return weeks;
};

export const isColumnDateLessThanCurrentDate = (myDate) => {
  return dayjs(myDate).isBefore(dayjs().format('YYYY-MM-DD'));
};

export const createBlob = (data) => {
  const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  const { base64, parts } = data;
  let blob;
  for (let i = 0; i < parts; i++) {
    blob = new Blob([base64ToArrayBuffer(base64[i])]);
  }

  return blob;
};

export const validateFileSize = (file) => {
  const fileSize = file.size / 1024;
  return fileSize <= 500;
};

export const getScheduleStatusFromCurrentDate = (fromDate, toDate) => {
  const fromDateObj = new Date(fromDate);
  const toDateObj = toDate ? new Date(toDate) : null;
  const currentObj = new Date();

  if (toDateObj && currentObj > toDateObj) {
    return 'Completed';
  } else if (currentObj < fromDateObj) {
    return 'Scheduled';
  } else {
    return 'Running';
  }
};

export const cellData = (data) => (data ? data : '-');

export const objectToArray = (passingObj) => {
  const result = Object.keys(passingObj).map((key) => {
    return { label: key, value: passingObj[key] };
  });

  return result;
};

//This functions is created to serve the survey flow page
export function getNextFieldByJumpingLogic(jumpingLogic, formValues, data) {
  let nextField = {};
  if (jumpingLogic.length === 1) {
    // if(jumpingLogic.conditions)
    nextField = {
      group: jumpingLogic[0]?.group_no,
      blockId: jumpingLogic[0]?.id,
    };
  } else {
    //get the values from formValues to compare against conditions in the jubmping logic
    let createdConditions = [];
    jumpingLogic[1].conditions.map((condition) => {
      const alias = findAliasById(condition.id, data);
      createdConditions.push({
        id: condition.id,
        answer: formValues[alias],
      });
      return null;
    });
    for (const jumping of jumpingLogic) {
      if (jumping.conditions === createdConditions) {
        return null;
      }
    }
  }
  return nextField;
}

const findAliasById = (id, data) => {
  for (const group of data) {
    for (const block of group.blocks) {
      if (block.id === id) {
        return block.question.alias;
      }
    }
  }
  return null;
};

export function getNextFieldByReferTo(referTo) {
  const nextField = {
    group: referTo?.group_no,
    blockId: referTo?.id,
  };
  return nextField;
}

export function getNextFieldByOptions(options, value) {
  let next = {};
  options.forEach((option) => {
    if (option.value === value) {
      next = {
        group: option?.referTo?.group_no,
        blockId: option?.referTo?.id,
      };
    }
  });
  return next;
}

export const findGroupTypeByBlockId = (blockId, data) => {
  for (const item of data) {
    const block = item.blocks.find((block) => block.id === blockId);
    if (block) {
      return item.type;
    }
  }
  return null;
};

export const isRequired = (item) => item.required || item.skip.id === '-1';

export const validateMinMax = (value, min, max) => {
  if (!value) {
    return Promise.reject(`Input must be between ${min} and ${max} characters`);
  }
  value = value.trim();

  if (value.length < min || value.length > max) {
    return Promise.reject(`Input must be between ${min} and ${max} characters`);
  }
  return Promise.resolve();
};

export const displayGroupNextBtn = (displayNextBtn, groupType) =>
  groupType !== SURVEY_FLOW_TYPES.REFERRING && displayNextBtn;

export const displayActionBtn = (finished, groupType) =>
  groupType === SURVEY_FLOW_TYPES.REFERRING && !finished;

export const formItemName = ({ id, question: { alias } }) => {
  if (typeof id !== 'string' && typeof alias !== 'string')
    throw new Error('Invalid question question id or alias');

  return [id, alias];
};

export const randomId = (length = 6) => {
  const symbols =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  const nanoid = customAlphabet(symbols, 21);
  return nanoid(length);
};

export const genFilename = (ext) =>
  `${dayjs().format('YYYY-MM-DD-HH:mm:ss')}_${getItem(
    localStorage,
    'username',
  )}_${nanoid(4)}.${ext}`;

export const getUrl = (filename, cmpId, fileType = 'Images') => {
  const username = getItem(localStorage, 'username');
  const org = username.split('@')[1];

  return `${process.env.REACT_APP_cdn}${process.env.REACT_APP_STAGE}/${
    org.split('-')[0]
  }/${fileType}/Survey/cmp-${cmpId}/${filename}`;
};
