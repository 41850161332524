import { node } from 'prop-types';

import { AppCard } from '..';
import './index.scss';

const RouteSelectionCard = ({ children, ...props }) => {
  return <AppCard {...props}>{children}</AppCard>;
};

RouteSelectionCard.propTypes = {
  children: node.isRequired,
};

export default RouteSelectionCard;
