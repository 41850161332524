import { Menu } from 'antd';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';

import AppIcon from 'components/AppComponent/AppIcon';

const getItem = (label, key, icon, children, type) => {
  return {
    label,
    key,
    icon,
    children,
    type,
  };
};

const rootSubmenuKeys = [];

const MenuTopics = ({ onClick }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const [path, setPath] = useState('');
  const [openKeys, setOpenKeys] = useState([]);

  useEffect(() => {
    setPath(location.pathname);
  }, [location.pathname]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const MenuItems = [
    getItem(
      <Link to="/"> {t('int-home')}</Link>,
      'home',
      <AppIcon icon="eva:home-fill" />,
    ),
  ];

  return (
    <Menu
      mode="inline"
      selectedKeys={[path.split('/')[2]]}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      onClick={() => onClick()}
      items={MenuItems}
    />
  );
};

export default MenuTopics;

MenuTopics.propTypes = {
  onClick: func,
};
