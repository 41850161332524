/*eslint-disable*/

import { Tag } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import useFlowContext from 'hooks/useFlowContext';
import AppSpin from 'components/AppComponent/AppSpin';
import { AppPageWrapper, RouteSelectionCard } from 'components/AppComponent';

const { CheckableTag } = Tag;

export function reduceRoutePlan(routePlan) {
  if (!Array.isArray(routePlan))
    throw new Error('Route plan must be an iterable.');

  return routePlan.reduce((hierarchy, location) => {
    const locCopy = {
      ...location,
      selected: false,
    };
    delete locCopy.locations;

    if (hierarchy) {
      hierarchy.locations.push(locCopy);
      return hierarchy;
    }

    return {
      id: location.type,
      slug: location.type_slug,
      locations: [locCopy],
    };
  }, null);
}

const RouteSelection = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { campaignConfig, isLoading, hierarchies, setHierarchies } =
    useFlowContext();

  const onLocationSelect = (location) => {
    const routePlan = campaignConfig?.route_plan;

    setHierarchies((hierarchies) => {
      const hierarchyIndex = hierarchies.findIndex(
        (hierarchy) => hierarchy.id === location.type,
      );

      /*remove all the locations that comes after the selected locations*/
      hierarchies.splice(hierarchyIndex + 1, Infinity);

      (function childLocations(routePlan) {
        /*deselect previous selected location & select location*/
        for (const hierarchy of hierarchies) {
          if (hierarchy.id === location.type) {
            let deselected = false;
            let newSelected = false;

            for (const loc of hierarchy.locations) {
              if (loc.selected) {
                loc.selected = false;
                deselected = true;
              }

              if (loc.id === location.id) {
                loc.selected = true;
                newSelected = true;
              }

              if (deselected && newSelected) {
                break;
              }
            }
          }
        }

        if (!routePlan?.length) return;

        for (const route of routePlan) {
          if (route.id == location.id) {
            if (!route.locations?.length)
              return navigate(
                `/survey/${id}`,
              ); /*no more location in the hierarchy. goto survey*/

            const locationsByHierarchy = reduceRoutePlan(route.locations);
            hierarchies.push(locationsByHierarchy);

            break;
          } else {
            childLocations(route.locations);
          }
        }
      })(routePlan);
    });
  };

  return (
    <AppPageWrapper>
      <AppSpin spinning={isLoading} />
      {hierarchies.map((hierarchy) => (
        <RouteSelectionCard key={hierarchy.id} title={hierarchy.slug}>
          {hierarchy.locations.map((location) => (
            <CheckableTag
              key={location.id}
              onChange={() => onLocationSelect(location)}
              checked={location.selected}
            >
              {location.name}
            </CheckableTag>
          ))}
        </RouteSelectionCard>
      ))}
    </AppPageWrapper>
  );
};

export default RouteSelection;
