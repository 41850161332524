import { useSurveyContext } from 'contexts/Survey';
import { matchPath, useLocation } from 'react-router-dom';

import { useSurveyVerificationContext } from 'contexts/Survey/surveyVerification';

//'/survey/:id/checklist/:checklistId'
//'/survey/:id/verification/:contactId'
const useFlowContext = () => {
  const { pathname } = useLocation();
  const routeInfo = matchPath(
    { path: '/survey/:id/verification/:contactId', caseSensitive: false },
    pathname,
  );

  const surveyContext = useSurveyContext();
  const surveyVerificationContext = useSurveyVerificationContext();

  return routeInfo ? surveyVerificationContext : surveyContext;
};

export default useFlowContext;
