/*eslint-disable*/
import { useMutation, useQuery } from '@tanstack/react-query';

import { getItem } from 'utils/Helper';
import ErrorHandler from 'utils/ErrorHandler';
import { CampaignManagerAPI } from 'utils/APIConfig';
import { AppNotification } from 'components/AppComponent';

const queryOptions = {
  onError: ErrorHandler,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  refetchOnMount: false,
  retry: 1,
};

export const useCampaignList = () =>
  useQuery(
    ['campaign-list'],
    () =>
      CampaignManagerAPI.get(`survey/get-campaigns`, {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')} `,
        },
      }),
    {
      ...queryOptions,
      select: ({ data: { data } }) => data,
    },
  );

export const useCampaignConfig = (campId) =>
  useQuery(
    ['campaign-config', campId],
    ({ queryKey }) =>
      CampaignManagerAPI.get(`survey/get-config/${queryKey[1]}`, {
        headers: { Authorization: `Bearer ${getItem(localStorage, 'token')}` },
      }),
    {
      select: ({ data }) => data?.data?.[0],
    },
  );

export const useCheckNumber = () =>
  useMutation(
    (body) =>
      CampaignManagerAPI.post('survey/check-number', body, {
        headers: { Authorization: `Bearer ${getItem(localStorage, 'token')}` },
      }),
    {
      ...queryOptions,
    },
  );

export const useSendOTP = () =>
  useMutation(
    (body) =>
      CampaignManagerAPI.post('verification/send-otp', body, {
        headers: { Authorization: `Bearer ${getItem(localStorage, 'token')}` },
      }),
    {
      ...queryOptions,
      onSuccess: () => AppNotification('An OTP has been sent', 'success'),
    },
  );

export const useSendSurveyData = () =>
  useMutation(
    (body) =>
      CampaignManagerAPI.post('survey/post-survey', body, {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')}`,
        },
      }),
    {
      ...queryOptions,
      onSuccess: () => AppNotification('Survey data has been saved', 'success'),
    },
  );

export const useFFList = () =>
  useMutation(
    (body) =>
      CampaignManagerAPI.post('survey/review/ff-list', body, {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')}`,
        },
      }),
    {
      ...queryOptions,
    },
  );

export const useContactList = () =>
  useMutation(
    (body) =>
      CampaignManagerAPI.post('survey/review/contacts', body, {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')}`,
        },
      }),
    {
      ...queryOptions,
    },
  );

export const useChangeContactStatus = () =>
  useMutation(
    (body) =>
      CampaignManagerAPI.post('survey/review/change-status', body, {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')}`,
        },
      }),
    {
      ...queryOptions,
    },
  );

export const useUpdateContact = () =>
  useMutation(
    (body) =>
      CampaignManagerAPI.post('survey/review/submit', body, {
        headers: {
          Authorization: `Bearer ${getItem(localStorage, 'token')}`,
        },
      }),
    {
      ...queryOptions,
    },
  );
