/*eslint-disable*/
import { useImmer } from 'use-immer';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParserFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParserFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

import { useSurveyContext } from '.';
import findNextGroups from './findNextGroups';
import { formItemName, getItem, removeItem } from 'utils/Helper';
import { AppNotification } from 'components/AppComponent';

const processBlockAnswer = (block, answer) => {
  switch (block.type) {
    case 'camera':
      const imgsUrl = answer.split(',');

      localStorage.setItem(
        `campaign_image_${block.id}`,
        JSON.stringify(imgsUrl),
      );

      return imgsUrl;
    case 'file_upload':
      const fileUrls = answer.split(',');
      localStorage.setItem(
        `campaign_audio_${block.id}`,
        JSON.stringify(fileUrls),
      );

      return fileUrls;
    case 'timepicker':
      return dayjs.utc(answer, 'HH:mm');
    case 'checkbox':
      const checkedItems = answer.split(',');
      return checkedItems;
    case 'multipleChoice':
      return answer;
    case 'otp':
      return answer;
    case 'terms':
      return answer;
    case 'dropdown':
      return answer;
    case 'date':
      return dayjs(answer, 'YYYY-MM-DD HH:mm');
    case 'numberInput':
      return answer;
    case 'textInput':
      return answer;
    case 'contactNo':
      return answer;
    case 'product':
      return answer;
    case 'url':
      return answer;
    case 'image':
      return answer;
    case 'video':
      return answer;
    case 'emoji_rating':
      return answer;
    case 'star_rating':
      return answer;
    case 'checklist' || 'sub-checklist':
      return answer;
    case 'audio_start':
      return answer;
    default:
      break;
  }
};

const SurveyVerificationContext = createContext(null);
export const useSurveyVerificationContext = () =>
  useContext(SurveyVerificationContext);

export const SurveyVerificationContextProvider = () => {
  const { id, contactId } = useParams();
  const navigate = useNavigate();

  const [finish, setFinish] = useState(true);
  const [contact, setContact] = useState([]);
  const [displayGroups, setDisplayGroups] = useImmer([]);
  const [originalAnswers, setOriginalAnswer] = useState([]);

  const { campaignConfig, form, ...surveyContextArgs } = useSurveyContext();

  useEffect(function getContact() {
    let contact = getItem(localStorage, ['contact', contactId].toString());

    try {
      contact = JSON.parse(contact);
      setContact(contact.survey_data);
    } catch (error) {
      AppNotification('Invalid contact!', 'error');
      return navigate(`/survey/verification/${id}`);
    }

    return () => removeItem(localStorage, ['contact', contactId].toString());
  }, []);

  useEffect(
    function populateGroups() {
      const surveyFLow = campaignConfig?.conditions?.web_survey;
      if (Array.isArray(contact) && Array.isArray(surveyFLow)) {
        const answers = {};

        const answerGroups = contact.map((answerGroup) => {
          let group = surveyFLow.find(
            (group) => group.group == answerGroup.group,
          );

          if (!group) throw new Error('Question group not found.');

          group = JSON.parse(JSON.stringify(group));

          const blocks = answerGroup.blocks.map((answerBlock) => {
            return group.blocks.find((block) => {
              if (block.id == answerBlock.id) {
                const processedAns = processBlockAnswer(
                  block,
                  answerBlock.answer,
                );
                const filedName = formItemName(block);
                answers[filedName[0]] = { [filedName[1]]: processedAns };
                block.originalAnswer = answerBlock.answer;
                return block;
              }
            });
          });

          group.blocks = blocks;
          return group;
        });

        form.setFieldsValue(answers);

        setDisplayGroups(answerGroups);
        setOriginalAnswer(answerGroups);
      }
    },
    [contact, campaignConfig],
  );

  useEffect(
    function setSurveyFinish() {
      setFinish(false);
    },
    [displayGroups],
  );

  const handleNext = useCallback(
    (payload) => {
      findNextGroups(
        campaignConfig?.conditions?.web_survey,
        displayGroups,
        payload,
        form,
      )
        .then((nextGroups) => {
          setDisplayGroups(nextGroups);
        })
        .catch((error) => console.log(error));
    },
    [campaignConfig, displayGroups, form],
  );

  return (
    <SurveyVerificationContext.Provider
      value={{
        ...surveyContextArgs,
        campaignConfig,
        form,

        finish,
        setFinish,
        flow_type: 'verification',
        displayGroups,
        handleNext,
        originalAnswers,
      }}
    >
      <Outlet />
    </SurveyVerificationContext.Provider>
  );
};
