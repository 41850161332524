import { Suspense } from 'react';
import { ConfigProvider } from 'antd';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import Router from './routes';
import useOnline from 'hooks/useOnline';
import NoConnection from 'components/NoConnection';
import { AuthContextProvider } from 'contexts/Auth';
import { AppLoader } from 'components/AppComponent';

import 'antd/dist/reset.css';

function App() {
  const online = useOnline();
  const queryClient = new QueryClient();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorText: '#434D58',
          colorPrimary: '#005BD4',
          colorWarning: '#e9a100',
          colorError: '#FF4D4F',
          colorSuccess: '#29823b',
          colorInfo: '#005BD4',
          colorLink: '#051367',
          fontSize: 14,
          borderRadius: 8,
          fontFamily: 'Work Sans',
        },
        components: {
          Button: {
            fontSize: 14,
            fontSizeLG: 15.32,
          },
          Input: {
            controlHeight: 40,
          },
          InputNumber: {
            controlHeight: 40,
          },
          DatePicker: {
            controlHeight: 40,
          },
          Select: {
            controlHeight: 40,
          },
        },
      }}
    >
      <Suspense fallback={<AppLoader />}>
        <QueryClientProvider client={queryClient}>
          {/* {process.env.REACT_APP_STAGE === 'Development' ? (
            <ReactQueryDevtools initialIsOpen={false} />
          ) : null} */}
          <AuthContextProvider>
            {online ? <Router /> : <NoConnection />}
          </AuthContextProvider>
        </QueryClientProvider>
      </Suspense>
    </ConfigProvider>
  );
}

export default App;
