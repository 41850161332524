import { Spin } from 'antd';
import { AppIcon } from '.';
import { array, bool, object } from 'prop-types';

const AppSpin = ({ spinning, iconProps, ...others }) => (
  <div className="app-loader">
    <Spin
      spinning={spinning}
      {...others}
      indicator={
        <AppIcon
          {...iconProps}
          style={{ width: 50, height: 50 }}
          icon="line-md:loading-loop"
        />
      }
    />
  </div>
);

AppSpin.propTypes = {
  spinning: bool,
  iconProps: object,
  others: array,
};

export default AppSpin;
