import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';

import { getItem, isAlphaNumericValue } from 'utils/Helper';
import { Accesses } from './AccessRoutes';

const AccessProtected = ({ children }) => {
  const { pathname } = useLocation();

  let resourceList = [];
  try {
    const resourceStr = getItem(localStorage, 'resource_access');

    resourceList = JSON.parse(resourceStr);
  } catch (error) {
    throw new Error('Invalid access list.');
  }

  let hasAccess;
  let splittedPath = pathname.split('/');

  // this condition is only for id which has alpha numeric value
  if (isAlphaNumericValue(splittedPath.at(-1))) {
    // remove last element which is number and make the main url
    splittedPath.pop();
    splittedPath = splittedPath.join('/');

    const selectedURLAccess = Accesses.find(
      (el) => el?.url == splittedPath,
    )?.access_code?.toString();
    if (resourceList.includes(Number(selectedURLAccess))) {
      hasAccess = true;
    } else {
      hasAccess = false;
    }
  } else if (isNaN(+splittedPath.at(-1))) {
    // enter if condition if the last element of the array is not a number
    // select requested url access code from accesses list
    const selectedURLAccess = Accesses.find(
      (el) => el?.url == pathname,
    )?.access_code?.toString();
    if (resourceList.includes(Number(selectedURLAccess))) {
      hasAccess = true;
    } else {
      hasAccess = false;
    }
  } else {
    // remove last element which is number and make the main url
    splittedPath.pop();
    splittedPath = splittedPath.join('/');

    if (!splittedPath) {
      splittedPath = '/';
    }

    const selectedURLAccess = Accesses.find(
      (el) => el?.url == splittedPath,
    )?.access_code?.toString();

    if (resourceList.includes(Number(selectedURLAccess))) {
      hasAccess = true;
    } else {
      hasAccess = false;
    }
  }

  if (hasAccess) {
    return children;
  } else {
    return <Navigate to="/not-authorized" />;
  }
};

export default AccessProtected;

AccessProtected.propTypes = {
  children: PropTypes.node,
};
