/*eslint-disable*/
import { Modal } from 'antd';
import { useEffect, useRef } from 'react';

import NotSupportedModal from 'components/NotSupportedModal';
import PermissionDeniedModal from 'components/AppPermissionModal/AppPermissionModal';

const { confirm } = Modal;

const handleModal = (content) =>
  confirm({
    open: true,
    icon: null,
    footer: null,
    centered: true,
    closable: false,
    mask: true,
    maskClosable: false,
    content: content,
  });

const useGeoLocation = () => {
  const geoLocation = useRef({ lat: null, long: null, radius: null });

  function getPosition({ coords }) {
    geoLocation.current.lat = coords.latitude;
    geoLocation.current.long = coords.longitude;
    geoLocation.current.radius = coords.accuracy;
  }

  function getPositionError() {
    return handleModal(<PermissionDeniedModal />);
  }

  // const handleGeoLocation = () => {
  //   if (!navigator.geolocation) return handleModal(<NotSupportedModal />);
  //   else {
  //     navigator.geolocation.getCurrentPosition(getPosition, getPositionError);
  //   }
  // };

  useEffect(() => {
    if (!navigator.geolocation) return handleModal(<NotSupportedModal />);
    else {
      navigator.geolocation.getCurrentPosition(getPosition, getPositionError);
    }
  }, []);

  return { geoLocation: geoLocation.current };
};

export default useGeoLocation;
