import { Input } from 'antd';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const { Search } = Input;

const AppSearchBar = ({
  style = { width: '250px' },
  onSearch,
  onChange,
  ...others
}) => {
  const { t } = useTranslation();
  return (
    <Search
      placeholder={t('int-enter-search-text')}
      style={style}
      onSearch={onSearch}
      onChange={onChange}
      enterButton
      {...others}
    />
  );
};

AppSearchBar.propTypes = {
  style: propTypes.object,
  onSearch: propTypes.func.isRequired,
  onChange: propTypes.func.isRequired,
};

export default AppSearchBar;
