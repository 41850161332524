/*eslint-disable*/
import { formItemName } from 'utils/Helper';
import { SURVEY_FLOW_TYPES } from 'utils/Constant';
import { validateHandleNext } from 'utils/validations';

const findNextGroups = async (surveyFLow, groups, payload, form) => {
  await validateHandleNext.validateAsync(payload);

  const displayGroups = JSON.parse(JSON.stringify(groups));

  const { groupId, blockId, nextGroupId, nextBlockId } = payload;

  for (let groupIndex = 0; groupIndex < displayGroups.length; groupIndex++) {
    const group = displayGroups[groupIndex];

    if (group.group == groupId) {
      if (group.type !== SURVEY_FLOW_TYPES.REFERRING) {
        for (const logic of group.jumping_logic) {
          for (const nextGroup of surveyFLow) {
            if (nextGroup.group === logic.group_no) {
              if (nextGroup.type !== SURVEY_FLOW_TYPES.REFERRING) {
                displayGroups.push(nextGroup);
                return displayGroups;
              } else {
                for (const block of nextGroup.blocks) {
                  if (block.id === logic.id) {
                    displayGroups.push({
                      ...nextGroup,
                      blocks: [block],
                    });
                    return displayGroups;
                  }
                }
              }

              break;
            }
          }
          break;
        }
      } else {
        let blockIndex;
        const block = group.blocks.find((block, index) => {
          if (block.id === blockId) {
            blockIndex = index;
            return true;
          }
        });

        if (!block) throw new Error('No block found with that id.');

        // remove all the block and groups comes after the current block.
        const slicedBlocks = group.blocks.splice(blockIndex + 1, Infinity);

        const slicedGroups = displayGroups.splice(groupIndex + 1, Infinity);

        slicedGroups.forEach((group) => {
          const fields = group.blocks.map((block) => formItemName(block));

          form.resetFields(fields);
        });

        const fields = slicedBlocks.map((block) => formItemName(block));

        form.resetFields(fields);

        /*refers to same group */
        done: {
          /*next group from block's refer to*/
          const nextGroup = surveyFLow.find(
            (nextGroup) => nextGroup.group == nextGroupId,
          );

          /*next block from block's refer to*/
          const nextBlock = nextGroup.blocks.find(
            (block) => block.id == nextBlockId,
          );

          /*refers to same block. end of done block*/
          if (group.group == nextGroupId) {
            if (!nextBlock) {
              throw new Error('Next question not found.');
            }

            group.blocks.push(nextBlock);
            return displayGroups;
          }

          conditionMet: {
            for (const logic of group.jumping_logic) {
              const conditionMet = logic.conditions.find((condition) => {
                const answer = form.getFieldValue(condition.question_id);

                if (answer === condition.answer) return true;
              });

              /*condition does not met. refer to where the block refers to. end of condition met block*/
              if (!conditionMet) break conditionMet;

              for (const nextGroup of surveyFLow) {
                if (nextGroup.group == logic.group_no) {
                  if (nextGroup.type !== SURVEY_FLOW_TYPES.REFERRING) {
                    displayGroups.push(nextGroup);
                    return displayGroups;
                  } else {
                    for (const nextBlock of nextGroup.blocks) {
                      if (nextBlock.id == logic.id) {
                        displayGroups.push({
                          ...nextGroup,
                          blocks: [nextBlock],
                        });
                        return displayGroups;
                      }
                    }
                  }

                  break;
                }
              }
            }
          }

          /*condition does not meet, and does not refers to a different group*/
          if (nextGroup.type !== SURVEY_FLOW_TYPES.REFERRING) {
            displayGroups.push(nextGroup);
            return displayGroups;
          } else {
            if (!nextBlock) {
              throw new Error('Next question not found.');
            }

            displayGroups.push({ ...nextGroup, blocks: [nextBlock] });
            return displayGroups;
          }
        }
      }

      break;
    }
  }
};

export default findNextGroups;
