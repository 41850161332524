import { Typography } from 'antd';

import { AppButton, AppRowContainer } from './AppComponent';

import offline from 'assets/images/offline.svg';

const { Title } = Typography;

const NoConnection = () => {
  return (
    <>
      <AppRowContainer
        gutter={[8, 8]}
        style={{
          height: '95vh',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={offline}
          width="200"
          height="auto"
          alt="connection not found"
          className="mb-2"
        />

        <Title level={4} style={{ margin: 0 }}>
          Unable to load
        </Title>
        <Title level={5} style={{ fontWeight: 'normal', margin: 0 }}>
          It seems your connection is lost or is still on its way.
        </Title>
        <AppButton className="mt-1" onClick={() => window.location.reload()}>
          Reload
        </AppButton>
      </AppRowContainer>
    </>
  );
};

export default NoConnection;
