import { node, string } from 'prop-types';
import { Card, Row, Typography } from 'antd';

import Page from '../Page';
import AppAnimateGroup from '../AppAnimateGroup';

const AppPageHeader = ({ title, children }) => {
  return (
    <div
      style={{ position: 'sticky', top: '64px', left: 0, right: 0, zIndex: 10 }}
    >
      <Page title={title}>
        <AppAnimateGroup type="top" height="auto">
          <Card className="app-header-card" key="header-comp">
            <Row justify="space-between" align="middle">
              <Typography.Title level={5} style={{ margin: 0 }}>
                {title}
              </Typography.Title>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  gap: 10,
                }}
              >
                {children}
              </div>
            </Row>
          </Card>
        </AppAnimateGroup>
      </Page>
    </div>
  );
};

AppPageHeader.propTypes = {
  title: string,
  children: node,
};

export default AppPageHeader;
