/*eslint-disable*/
import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

import { SurveyContextProvider } from 'contexts/Survey';
import { SurveyVerificationContextProvider } from 'contexts/Survey/surveyVerification';
import AuthGuard from 'guards/AuthGuard';
import GuestGuard from 'guards/GuestGuard';
import DashboardLayout from 'layouts/DashboardLayout';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
import AccessProtected from 'lib/AccessProtected';

/*pages*/
const Home = lazy(() => import('pages/Home'));
const Login = lazy(() => import('pages/Auth/Login'));
const NotFound = lazy(() => import('pages/Others/NotFound'));
const ForgotPassword = lazy(() => import('pages/Auth/ForgotPassword'));
const NotAuthorized = lazy(() => import('./../pages/Others/NotAuthorized'));

const SurveyForm = lazy(() => import('./../pages/SurveyForm'));
const ContactSurvey = lazy(() => import('./../pages/ContactSurvey'));
const SurveyRoute = lazy(() => import('./../pages/SurveyRoute'));
const SurveyVerification = lazy(() => import('./../pages/SurveyVerification'));
const RouteSelection = lazy(() => import('./../pages/RouteSelection'));

const Router = () => {
  return useRoutes([
    {
      path: '/survey/:id/:jwt',
      element: <SurveyRoute />,
    },
    {
      path: '/auth',
      element: <LogoOnlyLayout />,
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'forgot-password',
          element: (
            <GuestGuard>
              <ForgotPassword />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '*',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '*',
          element: <NotFound />,
        },
        {
          path: 'not-authorized',
          element: <NotAuthorized />,
        },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/',
          element: (
            <AccessProtected>
              <Home />
            </AccessProtected>
          ),
        },
        {
          path: '/contact-survey',
          element: <ContactSurvey />,
        },
        {
          element: <SurveyContextProvider />,
          children: [
            {
              path: '/route/:id',
              element: <RouteSelection />,
            },
            {
              path: '/survey/verification/:id',
              element: (
                <AccessProtected>
                  <SurveyVerification />
                </AccessProtected>
              ),
            },
            {
              path: '/survey/:id',
              element: <SurveyForm />,
            },
            {
              element: <SurveyVerificationContextProvider />,
              children: [
                {
                  path: '/survey/:id/verification/:contactId',
                  element: <SurveyForm />,
                },
              ],
            },
          ],
        },
      ],
    },
  ]);
};

export default Router;
