/*eslint-disable*/

import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { createContext, useContext } from 'react';

import { useLogin } from 'api/Auth.api';
import { trimmedAndLowerCase } from 'utils/Formatter';
import { removeAll, setItem } from 'utils/Helper';

export const AuthContext = createContext({});
export const useAuthContext = () => useContext(AuthContext);

const getDeviceInfo = () => {
  const navigator_info = window.navigator;
  const screen_info = window.screen;
  let devideId = navigator_info.mimeTypes.length;

  devideId += navigator_info.userAgent.replace(/\D+/g, '');
  devideId += navigator_info.plugins.length;
  devideId += screen_info.height || '';
  devideId += screen_info.width || '';
  devideId += screen_info.pixelDepth || '';

  return devideId;
};

export const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const { mutate: mutateLogin, isLoading } = useLogin();

  const login = (values) => {
    mutateLogin(
      {
        username: trimmedAndLowerCase(values.email),
        password: values.password,
        platform: 61,
        deviceInfo: {
          device_id: getDeviceInfo(),
          app_version: 'ubl-2.4.7',
          security_patch: '2022-11-05',
          ui_version: '10278734',
          android_version: '13',
          ip_address: '10.0.2.15',
          api_version: '33',
          manufacture: 'Google',
          user_type: 'userdebug',
          app_version_code: 109,
          imei: 'not_found',
          model: 'sdk_gphone_x86_64',
          network_type: 'wifi',
          mobile_number: '+15551234567',
          brand: 'google',
        },
      },
      {
        onSuccess: (response) => {
          const data = response?.data?.data;

          setItem(localStorage, 'email', values.email);
          setItem(localStorage, 'token', data.token);

          setItem(localStorage, 'username', data?.username);
          setItem(
            localStorage,
            'resource_access',
            JSON.stringify(data?.access_list),
          );
          setItem(localStorage, 'user_type', data?.user_type);
          setItem(localStorage, 'agency_id', data?.agency_id);

          navigate('/');
        },
      },
    );
  };

  const logout = () => {
    removeAll(localStorage);
    navigate('/auth/login');
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
