import { Typography } from 'antd';
import dayjs from 'dayjs';
import { Outlet } from 'react-router-dom';

const { Text } = Typography;

const LogoOnlyLayout = () => {
  return (
    <div className="container">
      <Outlet />
      <div style={{ position: 'absolute', bottom: 20 }}>
        <Text>
          [{process.env.REACT_APP_STAGE}] Copyright © PPL{' '}
          {dayjs().format('YYYY')}
        </Text>
      </div>
    </div>
  );
};

export default LogoOnlyLayout;
