/*eslint-disable*/
import dayjs from 'dayjs';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Button,
  Card,
  Drawer,
  Dropdown,
  Layout,
  Space,
  Typography,
} from 'antd';

import MenuTopics from 'container/MenuTopics';
import logo from 'assets/images/PPL_Logo.svg';
import { useAuthContext } from 'contexts/Auth';
import useResponsive from 'hooks/useResponsive';
import AppScrollBar from 'components/AppScrollBar';
import { getFirstLetter, getItem } from 'utils/Helper';
import AppErrorBoundary from 'components/AppErrorBoundary';
import { AppConfirmModal, AppIcon, AppLoader } from 'components/AppComponent';

const { Header } = Layout;
const { Title, Text } = Typography;

const DashboardLayout = () => {
  const { pathname } = useLocation();
  const [navIsVisible, setNavIsVisible] = useState(false);

  const openSideNav = () => {
    setNavIsVisible(true);
  };

  const closeSideNav = () => {
    setNavIsVisible(false);
  };

  return (
    <Layout style={{ minHeight: '100vh', height: '100vh' }}>
      <Drawer
        title={<Profile />}
        placement="left"
        onClose={closeSideNav}
        open={navIsVisible}
        closable={false}
      >
        <AppScrollBar className="full-height">
          <MenuTopics onClick={closeSideNav} />
        </AppScrollBar>
      </Drawer>

      <Header className="app-standard-header">
        <Button
          type="text"
          icon={
            <AppIcon
              icon="majesticons:menu-line"
              style={{ height: 40, width: 40 }}
            />
          }
          onClick={openSideNav}
        />
        <img src={logo} alt="ppl logo" width="60px" height="auto" />
      </Header>

      <Suspense fallback={<AppLoader />}>
        <AppErrorBoundary>
          <Outlet />
        </AppErrorBoundary>
      </Suspense>

      <footer
        style={{
          position: 'fixed',
          width: '100%',
          bottom: 0,
          zIndex: 1000,
          display: pathname.includes('survey') ? 'none' : 'block',
        }}
      >
        <Card className="center footer-card">
          [{process.env.REACT_APP_STAGE}] Copyright © PPL
          {dayjs().format('YYYY')}
        </Card>
      </footer>
    </Layout>
  );
};

export default DashboardLayout;

function Profile() {
  const { t } = useTranslation();
  const deviceState = useResponsive();
  const navigate = useNavigate();
  const { logout } = useAuthContext();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const navigateToRespectiveDetailsPage = () => {
    const userType = getItem(localStorage, 'user_type');

    if (userType === 'org_mgt') {
      navigate(
        `/user-manager/user-details/${getItem(localStorage, 'user_id')}`,
      );
    } else {
      navigate(`/ff-manager/ff-details/${getItem(localStorage, 'user_id')}`);
    }
  };

  const items = [
    {
      key: 1,
      label: t('int-account-profile'),
      onClick: navigateToRespectiveDetailsPage,
      icon: <AppIcon icon="solar:user-bold" />,
    },
    {
      key: 2,
      label: t('int-security'),
      onClick: () => navigate('/manager/security'),
      icon: <AppIcon icon="material-symbols:security" />,
    },
    {
      type: 'divider',
    },
    {
      key: 3,
      label: t('int-logout'),
      danger: true,
      onClick: () => setShowConfirmModal(true),
      icon: <AppIcon icon="ic:round-logout" />,
    },
  ];

  return (
    <div
      style={{
        background: '#fff',
        padding: `${!deviceState.isDesktop ? '0' : '0 16px 10px 24px'}`,
        position: 'sticky',
        top: 0,
        zIndex: 100,
        borderBottom: `${deviceState.isDesktop ? '1px solid #f0f0f0' : 'none'}`,
      }}
    >
      <Dropdown
        menu={{
          items,
        }}
      >
        <Space style={{ cursor: 'pointer' }}>
          <Avatar
            size="large"
            shape="circle"
            style={{ backgroundColor: '#005BD4', fontSize: '1.5em' }}
          >
            {getFirstLetter(getItem(localStorage, 'username'))}
          </Avatar>
          <Title level={5} className="mt-1" style={{ fontWeight: 500 }}>
            {getItem(localStorage, 'username')} <br />
            <Text ellipsis className="nt-4" style={{ fontWeight: 'normal' }}>
              {getItem(localStorage, 'role_name')}
            </Text>
          </Title>
          <AppIcon icon="uil:angle-down" style={{ marginTop: -15 }} />
        </Space>
      </Dropdown>

      <AppConfirmModal
        isVisible={showConfirmModal}
        type="warning"
        title="Are you sure you want to logout?"
        okText="Logout"
        cancelText="Keep me logged in"
        onCancelClick={() => setShowConfirmModal(false)}
        onOkClick={logout}
      />
    </div>
  );
}
