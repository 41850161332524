/*eslint-disable*/
import dayjs from 'dayjs';
import { Form } from 'antd';
import { node } from 'prop-types';
import { useImmer } from 'use-immer';
import { Outlet, useParams } from 'react-router-dom';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import findNextGroups from './findNextGroups';
import useGeoLocation from 'hooks/useGeoLocation';
import { SURVEY_FLOW_TYPES } from 'utils/Constant';
import { useCampaignConfig } from 'api/Campaign.api';
import { reduceRoutePlan } from 'pages/RouteSelection';

const { useForm } = Form;

const SurveyContext = createContext(null);
export const useSurveyContext = () => useContext(SurveyContext);

export const SurveyContextProvider = () => {
  const { id: campaignId } = useParams();
  if (isNaN(campaignId)) {
    throw new Error('Invalid campaign id.');
  }

  const [form] = useForm();
  const contactNo = useRef(null);
  const { geoLocation } = useGeoLocation();
  const surveyStartTime = useRef(dayjs().format('YYYY-MM-DD HH:mm:ss'));

  const [hierarchies, setHierarchies] = useImmer([]);
  const [displayGroups, setDisplayGroups] = useImmer([]);
  const [finish, setFinish] = useState(false);

  const [ffList, setFfList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  const { data: campaignConfig, isLoading: isConfigLoading } =
    useCampaignConfig(campaignId);

  useEffect(() => {
    setIsloading(isConfigLoading);
  }, [isConfigLoading]);

  useEffect(
    function setInitialGroup() {
      if (
        Array.isArray(campaignConfig?.conditions?.web_survey) &&
        campaignConfig?.conditions?.web_survey.length > 0
      ) {
        const surveyFLow = campaignConfig?.conditions?.web_survey;
        const firstGroup = surveyFLow[0];

        if (firstGroup.type !== SURVEY_FLOW_TYPES.REFERRING) {
          setDisplayGroups([firstGroup]); // for NON-REFERRING groups display the whole group.
        } else {
          setDisplayGroups([{ ...firstGroup, blocks: [firstGroup.blocks[0]] }]); //for REFERRING groups display only the first block of the group.
        }
      }
    },
    [campaignConfig],
  );

  useEffect(
    function setFirstHierarchy() {
      const routePlan = campaignConfig?.route_plan;

      if (Array.isArray(routePlan)) {
        const locationsByHierarchy = reduceRoutePlan(routePlan);

        setHierarchies((hierarchies) => {
          hierarchies.push(locationsByHierarchy);
        });
      }
    },
    [campaignConfig],
  );

  useEffect(
    function setSurveyFinish() {
      setFinish(false);
    },
    [displayGroups],
  );

  const handleNext = useCallback(
    async (payload) => {
      try {
        const nextGroups = await findNextGroups(
          campaignConfig?.conditions?.web_survey,
          displayGroups,
          payload,
          form,
        );

        setDisplayGroups(nextGroups);
      } catch (error) {
        console.log(error);
      }
    },
    [campaignConfig, displayGroups, form],
  );

  return (
    <SurveyContext.Provider
      value={{
        flow_type: 'main',
        form,
        isLoading,
        setIsloading,
        campaignConfig,
        displayGroups,
        handleNext,
        hierarchies,
        setHierarchies,
        contactNo,
        finish,
        setFinish,
        geoLocation,
        surveyStartTime: surveyStartTime.current,
        ffList,
        setFfList,
        contactList,
        setContactList,
        selectedRowKeys,
        setSelectedRowKeys,
      }}
    >
      <Outlet />
    </SurveyContext.Provider>
  );
};

SurveyContextProvider.propTypes = {
  children: node,
};
