import Joi from 'joi';

export const validateHandleNext = Joi.object({
  groupId: Joi.string()
    .required()
    .error(new Error('groupId must be a number string.')),
  blockId: Joi.string().error(new Error('blockId must be a number string.')),
  nextGroupId: Joi.string().error(
    new Error('nextGroupId must be a number string.'),
  ),
  nextBlockId: Joi.string().error(
    new Error('nextBlockId must be a number string.'),
  ),
});
