import { Typography } from 'antd';
import { AppIcon, AppButton } from '../AppComponent';

const PermissionDeniedModal = () => {
  const handleReload = () => location.reload();

  return (
    <>
      <Typography.Title level={4}>
        Please allow to access required permissions to function this website
        properly.
      </Typography.Title>
      <Typography.Title level={5}>
        Go to you browser settings &gt; Privacy & security &gt; Site settings
        and allow required premission
      </Typography.Title>
      <AppButton onClick={handleReload}>
        Reload
        <AppIcon icon="mdi:reload" />
      </AppButton>
    </>
  );
};

export default PermissionDeniedModal;
