import { Row } from 'antd';
import PropTypes from 'prop-types';

import AppAnimateGroup from 'components/AppAnimateGroup';

const AppRowContainer = ({ children, ...others }) => {
  return (
    <AppAnimateGroup
      animateStyle={{ flexDirection: 'row' }}
      component={Row}
      type="bottom"
      ease="easeInOutQuart"
      height="auto"
      gutter={[
        { xs: 8, sm: 8, md: 16, lg: 16 },
        { xs: 8, sm: 8, md: 16, lg: 16 },
      ]}
      {...others}
    >
      {children}
    </AppAnimateGroup>
  );
};

export default AppRowContainer;
AppRowContainer.propTypes = {
  children: PropTypes.node,
};
