/**
 * This array oaf url is important to check the authorization of the url.
 * All protected urls should be here with their respected access code.
 * ###IMPORTANT###
 * For Urls that are dynamic like /report-manager/custom-report-update/:id
 * last parameter is not required here. /:id or /:param is needed. Only the base
 * url will be added.
 */

export const Accesses = [
  { url: '/survey/verification', access_code: 251 },
  { url: '/', access_code: 178 },
  { url: '/contact-survey', access_code: 178 },
  { url: '/route', access_code: 178 },
];
