import { Typography } from 'antd';

const NotSupportedModal = () => {
  return (
    <>
      <Typography.Title level={4}>
        Your browser needs to be updated to function this website properly.
        Please update your browser!
      </Typography.Title>
    </>
  );
};

export default NotSupportedModal;
