import { Spin } from 'antd';

import './index.style.scss';
import { AppIcon } from '..';

const AppLoader = () => {
  return (
    <div className="app-loader">
      <Spin
        align="center"
        indicator={
          <AppIcon
            style={{ width: 50, height: 50 }}
            icon="line-md:loading-loop"
          />
        }
      />
    </div>
  );
};

export default AppLoader;
